function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  background: #f5f5f5;\n\n  .form-container {\n    width: 300px;\n    height: 420px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    background: #FFFFFF;\n    box-shadow: 0 2px 3px hsla(0,0%,4%,.1), 0 0 0 1px hsla(0,0%,85%,.1);\n  }\n  form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 90%;\n  }\n  .input-login {\n    width: 90%;\n    margin-bottom: 10px;\n    display: flex;\n    flex-direction: column;\n  }\n  label {\n    font-size: 14px;\n    line-height: 1;\n    color: #9198a4;\n    text-transform: uppercase;\n    font-weight: 700;\n  }\n  input {\n    font-size: 14px;\n    font-weight: 600;\n    color: #727272;\n    border: 1px solid #f5f5f5 !important;\n    height: 36px;\n    padding-left: 30px;\n  }\n  hr {\n    width: 90%;\n    background-color: #dbdbdb;\n    border: none;\n    display: block;\n    height: 1px;\n    margin: 15px;\n  }\n  .icon-form {\n    position: relative;\n    top: 28px;\n    left: 10px;\n    width: 20px;\n  }\n\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1lkdurd-0"
})(_templateObject());