function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  font-size: 17px;\n  color: ", ";\n  "]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100", ";\n  height: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-9rhb2q-0"
})(_templateObject(), props => props.full ? 'vw' : '%', props => props.height ? props.height + 'px' : '100%');
export const TextError = styled.p.withConfig({
  displayName: "TextError",
  componentId: "sc-9rhb2q-1"
})(_templateObject2(), props => props.color);