function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  width: ", "%;\n  height: 25px;\n  background: #93BDFF;\n  border-radius: 20px;\n  display: flex;\n  align-items: center;\n"]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  width: 275px;\n  height: 25px;\n  background: #E2E2EB;\n  border-radius: 20px;\n  position: relative;\n  display: inline-block;\n  .total {\n    position: absolute;\n    top: 6px;\n    left: 6px;\n  }\n  .order-size {\n    position: absolute;\n    top: 6px;\n    right: 6px;\n  }\n\n  .elapsed-time {\n    position: absolute;\n    top: 6px;\n    left: ", "px;\n  }\n\n  .shift-begin {\n    position: absolute;\n    top: 29px;\n    left: 0px;\n  }\n  .shift-end {\n    position: absolute;\n    top: 29px;\n    right: 0px;\n  }\n  p { font-weight: bold; }\n\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", ";\n  border-right: 2px solid #EAEAEE;\n  display: flex;\n  flex-direction: column;\n  justify-content: ", ";\n  align-items: ", ";\n\n  .header {\n    width: 100%;\n    height: 30%;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    margin-left: 20px;\n  }\n\n  .sub-item {\n    width: 100%;\n    height: 70%;\n    display: flex;\n    justify-content: space-evenly;\n  }\n  .sub-item-flex {\n    width: 100%;\n    height: 70%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-left: 13px;\n    padding-right: 20px;\n  }\n  .item-header {\n    justify-content: flex-start;\n    margin-left: 20px;\n    margin-top: 6px;\n  }\n  .title {\n    color: #BFBFBF;\n    font-weight: bold;\n    font-size: 20px;\n  }\n  .value {\n    font-size: 40px;\n    font-weight: bold;\n  }\n  .percent {\n    font-weight: bold;\n    font-size: 20px;\n  }\n  .color-red {\n    color: #EA3939;\n  }\n  .color-green {\n    color: #1BC100;\n  }\n  .shift {\n    width: 35px;\n    height: 35px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: bold;\n  }\n  .is-selected {\n    background: #2376F5;\n    color: #FFFFFF;\n    border-radius: 5px;\n  }\n\n  &:last-child { border: none; }\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: ", "px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';

function calculatePosition(size) {
  if (size < 10) return 0;
  if (size <= 121) return size + 15;
  return 121;
}

export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "akopa1-0"
})(_templateObject());
export const Row = styled.div.withConfig({
  displayName: "Row",
  componentId: "akopa1-1"
})(_templateObject2(), props => props.height, props => props.borderBottom ? 'border-bottom: 2px solid #EAEAEE;' : '', props => props.borderRight ? 'border-right: 2px solid #EAEAEE;' : '', props => props.borderTop ? 'border-top: 2px solid #EAEAEE;' : '');
export const Item = styled.div.withConfig({
  displayName: "Item",
  componentId: "akopa1-2"
})(_templateObject3(), props => props.width ? props.width : 205, props => props.height ? "".concat(props.height, "px") : '100%', props => props.justify ? props.justify : 'center', props => props.align ? props.align : 'center');
export const Progress = styled.div.withConfig({
  displayName: "Progress",
  componentId: "akopa1-3"
})(_templateObject4(), props => calculatePosition(props.size));
export const Bar = styled.div.withConfig({
  displayName: "Bar",
  componentId: "akopa1-4"
})(_templateObject5(), props => props.size <= 4 ? 4 : props.size);