/* eslint-disable no-unused-vars */
import React from 'react';
import { Router } from '@reach/router';
import Home from "../Home";
import SecondLayout from "../SecondLayout";
import General from "../General";

const Routes = props => React.createElement(Router, {
  style: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden'
  }
}, React.createElement(Home, {
  path: "/celula/1"
}), React.createElement(SecondLayout, {
  path: "/celula/2"
}), React.createElement(General, {
  path: "/general"
}));

export default Routes;