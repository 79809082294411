function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  overflow: auto;\n  flex: 1;\n"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n    position: absolute;\n    z-index: 100;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    max-width: ", "px;\n    background-color: ", ";\n    animation: .4s ", ";\n    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.3);\n  "]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 8;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 90;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { fadeInLeft } from 'react-animations';
import colors from "../../colors"; // TODO: Update to use react-spring and animate when hide too;

const enterLeftAnimation = keyframes(_templateObject(), fadeInLeft);
const Wrapper = styled.div.withConfig({
  displayName: "Wrapper",
  componentId: "ojn5ie-0"
})(_templateObject2());
const Outside = styled.div.withConfig({
  displayName: "Outside",
  componentId: "ojn5ie-1"
})(_templateObject3());
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "ojn5ie-2"
})(_templateObject4(), ({
  maxWidth,
  backgroundColor
}) => css(_templateObject5(), maxWidth || 300, backgroundColor || colors.grayLight, enterLeftAnimation));
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "ojn5ie-3"
})(_templateObject6());

const Drawer = props => {
  const {
    children,
    onClickToHide,
    backgroundColor,
    paddingTop,
    isOpen,
    maxWidth
  } = props;
  if (!isOpen) return null;

  const handleHide = () => {
    onClickToHide();
  };

  return React.createElement(Wrapper, null, React.createElement(Container, {
    maxWidth,
    backgroundColor
  }, React.createElement(Content, {
    style: {
      paddingTop
    }
  }, typeof children === 'function' ? children({
    isOpen,
    hideDrawer: handleHide
  }) : children)), React.createElement(Outside, {
    onClick: handleHide
  }));
};

Drawer.defaultProps = {
  children: () => {}
};
Drawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.number,
  onClickToHide: PropTypes.func,
  onHide: PropTypes.func
};
export default Drawer;