/* eslint-disable max-len */
import React from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Container, Row, Item, Progress, Bar } from './styles';
import { prettyNumber } from '../../../utils/helpers';
import moment from 'moment';


const isLessThan = (value_today, value_yesterday) => Number(value_today) < Number(value_yesterday);


function PoInfo({ data, shift, small }) {
  const locale = 'pt-br';
  let production_today = prettyNumber(data?.production_today, 1, locale);
  let production_yesterday = prettyNumber(data?.production_yesterday, 1, locale);
  //let oee_today = data?.oee_today * 100;
  //let oee_yesterday = data?.oee_yesterday * 100;
  let total_production = prettyNumber(data?.total, 1, locale);
  let order = `${data?.nm_product} - ${data?.nm_productfamily} - ${data?.nm_client} + ${data?.txt_product}`;
  let shift_one_prod = prettyNumber(data?.current_shift_production?.[0]?.net_production.toFixed(), 1, locale);
  let shift_two_prod = prettyNumber(data?.current_shift_production?.[1]?.net_production.toFixed(), 1, locale);
  let shift_three_prod = prettyNumber(data?.current_shift_production?.[2]?.net_production.toFixed(), 1, locale);

  let production_progress = data?.total * 100 / data?.production_programmed;
  if (production_progress > 100) production_progress = 100;
  if (production_progress < 0 || isNaN(production_progress)) production_progress = 0;

  let shift_progress = 0;
  let current_time = moment();
  let start_shift = shift?.[0]?.shift?.begintime;
  let end_shift = shift?.[0]?.shift?.endtime;
  let begin = moment(moment().format(`YYYY-MM-DDT${start_shift}`));
  let end = moment(moment().format(`YYYY-MM-DDT${end_shift}`));
  let duration = moment.duration(end.diff(begin));
  duration = duration.asHours();

  let current_duration = moment.duration(current_time.diff(begin));
  let elapsed_time = `${current_duration.hours()}:${(current_duration.minutes() < 10) ? 0 : ''}${current_duration.minutes()}`; 
  current_duration = current_duration.asHours();
  
  shift_progress = current_duration * 100 / duration;
  
  if (shift_progress > 100) shift_progress = 100;
  if (shift_progress < 0 || isNaN(shift_progress)) shift_progress = 0;
  if (order?.includes('null')) order = 'Sem descrição';
  console.log(isLessThan(production_today?.value, production_yesterday?.value));

  return (
    <Container>
      <Row height={(small) ? 70 : 100}>
        <Item>
          <div className="sub-item item-header">
            <span className="title">Produção HOJE</span>
          </div>

          <div className="sub-item">
            <p className="value">{ production_today?.formatted || 0 }</p>
            <span className="percent"> {production_yesterday.formatted} 
            {
              (isLessThan(production_today?.value, production_yesterday?.value)) ? <FaArrowDown size={12} color="#EA3939" /> :
              <FaArrowUp size={22} color="#1BC100" />
            }
                 
            </span>
          </div>
        </Item>
        <Item>
          <div className="sub-item item-header">
            <span className="title">OEE HOJE</span>
          </div>

          <div className="sub-item">
            <p className="value"> {/*oee_today.toFixed(1)*/}-</p>
            <span className="percent"> - {/*oee_yesterday.toFixed(1)% <FaArrowDown size={12} color="#EA3939" /> */}	</span>
          </div>
        </Item>
        <Item>
          <div className="sub-item-flex">
            <span className="title">OP</span>
            <p style={{ fontSize: 26 }}> { data?.id_order } </p>
          </div>

          <div className="sub-item-flex">
            <span className="title">Total</span>
            <p className="value"> {total_production?.formatted}</p>
          </div>
        </Item>
      </Row>

      <Row borderBottom borderTop height={(small) ? 24 : 40}
        style={{ width: 615, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <p style={{ fontSize: 17, fontWeight: 'bold', paddingLeft: 8, overflow: 'hidden', textOverflow: 'ellipsis' }}> 
          {order} 
        </p>
      </Row>

      <Row height={(small) ? 78 : 110}>
        <Item width={307.5}>
          <div className="sub-item" style={{ paddingTop: 10 }}>
            <Progress>
              <Bar size={production_progress} />
              <p className="total">{new Intl.NumberFormat().format(data?.total)}</p>
              <p className="order-size">{new Intl.NumberFormat().format(data?.production_programmed)}</p>
            </Progress>
          </div>
          <div className="sub-item" >
            <Progress size={shift_progress}>
              <Bar size={shift_progress} />
              <p className="elapsed-time"> {elapsed_time} </p>
              <p className="shift-begin">{start_shift?.slice(0,5)}</p>
              <p className="shift-end">{end_shift?.slice(0,5)}</p>
            </Progress>
          </div>
        </Item>

        <Item width={307.5}>
          <div className="header">
            <span className="title">Produção TURNO</span>
          </div>

          <div className="sub-item">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <p className="value" style={{ fontSize: '28px' }}>
                {shift_one_prod.formatted} <FaArrowUp size={22} color="#1BC100" />
              </p>
              <span className={`shift ${(shift?.[0]?.cd_shift === '1') ? 'is-selected' : ''} `}> <p>T1</p> </span>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <p className="value" style={{ fontSize: '28px' }}> 
                {shift_two_prod.formatted}  <FaArrowDown size={22} color="#EA3939" />
              </p>
              <span className={`shift ${(shift?.[0]?.cd_shift === '2') ? 'is-selected' : ''} `}> <p>T2</p> </span>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <p className="value" style={{ fontSize: '28px' }}> 
                {shift_three_prod.formatted }  <FaArrowDown size={22} color="#EA3939" />
              </p>
              <span className={`shift ${(shift?.[0]?.cd_shift === '3') ? 'is-selected' : ''} `}> <p>T3</p> </span>
            </div>
            
          </div>
        </Item>
      </Row>

    </Container>
  );
}

export default PoInfo;