function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n    svg {\n      width: auto;\n      height: ", ";\n    }\n    path,\n    circle {\n      fill: ", "\n    }\n  "]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["height: ", "px;"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n  line-height: 1;\n  ", "\n  font-size: ", ";\n  color: ", ";\n  ", "\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled, { css } from 'styled-components';
import { baseFontSize } from "../../settings";
const Wrapper = styled.span.withConfig({
  displayName: "Wrapper",
  componentId: "sc-16166ba-0"
})(_templateObject(), ({
  hasChildren,
  color,
  size
}) => css(_templateObject2(), !hasChildren && css(_templateObject3(), size || baseFontSize), size ? size + 'px' : 'inherit', color || 'inherit', hasChildren && css(_templateObject4(), size ? size + 'px' : 'auto', color || 'inherit')));

const Icon = (_ref) => {
  let {
    children,
    color,
    name,
    size
  } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, ["children", "color", "name", "size"]);

  return React.createElement(Wrapper, _extends({
    hasChildren: children
  }, {
    color,
    size
  }, rest), children || name && React.createElement("i", {
    className: name
  }));
};

export default Icon;