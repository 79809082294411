function _templateObject7() {
  const data = _taggedTemplateLiteral(["width: ", "px;"]);

  _templateObject7 = function () {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral(["justify-content: ", ";"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n    display: flex !important;\n    flex-direction: column;\n    flex: 1;\n  "]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["flex: 1;"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  padding-left: 0;\n  padding-right: 0;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled, { css } from 'styled-components';
import { Grid as StyledGrid, Row as StyledRow, Col as StyledCol } from 'react-styled-flexboxgrid';
export const Grid = styled(StyledGrid).withConfig({
  displayName: "Grid",
  componentId: "waroin-0"
})(_templateObject());
export const Row = styled(StyledRow).withConfig({
  displayName: "Row",
  componentId: "waroin-1"
})(_templateObject2(), ({
  isExpanded
}) => isExpanded && css(_templateObject3()));
export const Col = styled(StyledCol).withConfig({
  displayName: "Col",
  componentId: "waroin-2"
})(_templateObject4(), ({
  isExpanded
}) => isExpanded && css(_templateObject5()), ({
  align
}) => css(_templateObject6(), align), ({
  size
}) => size && css(_templateObject7(), size));
export { default as Text } from "./lib/Text";
export { default as Card } from "./lib/Card";
export { default as Circle } from "./lib/Circle";
export { default as Drawer } from "./lib/Drawer";
export { default as Title } from "./lib/Title";
export { default as Group } from "./lib/Group";
export { default as Fill } from "./lib/Fill";
export { default as Icon } from "./lib/Icon";
export { default as Spacer } from "./lib/Spacer";
export { default as Button } from "./lib/Button";
export { default as Box } from "./lib/Box";
export { default as FormContainer } from "./lib/FormContainer";
export { Form, FieldRepeater } from "./lib/FormContainer";
export { default as Modal } from "./lib/Modal";
export { default as Error } from "./lib/Error";
export { default as Loading } from "./lib/Loading";
export { default as LoadContainer } from "./lib/LoadContainer";
export { default as NavigationTab } from "./lib/NavigationTab";