function _templateObject3() {
  const data = _taggedTemplateLiteral(["border-style: ", ""]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  align-items: ", ";\n  background-color: ", ";\n  border-radius: ", "px;\n  border: ", ";\n  display: flex;\n  flex-direction: ", ";\n  justify-content: flex-start;\n  margin: ", "px;\n  overflow: hidden;\n  padding: ", "px;\n  width: ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors, { convertHexToRGB } from "../../colors";
import { elevation } from "../../settings";
const Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "sc-1dolstp-0"
})(_templateObject(), ({
  borderRadius,
  borderStyle,
  color,
  isBordered,
  isElevated,
  isFullWidth,
  isInline,
  margin,
  opacity,
  padding
}) => css(_templateObject2(), isInline ? 'center' : 'initial', color ? convertHexToRGB(color, opacity) : convertHexToRGB(colors.white, opacity), borderRadius, isBordered ? "1px solid ".concat(colors.gray1) : 'none', isInline ? 'row' : 'column', margin ? margin : '0', padding, isFullWidth ? '100%' : 'auto', borderStyle && css(_templateObject3(), borderStyle), isElevated && elevation));
Box.defaultProps = {
  borderRadius: 6,
  padding: 10
};
Box.propTypes = {
  borderRadius: PropTypes.number,
  color: PropTypes.number,
  isBordered: PropTypes.bool,
  isElevated: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isInline: PropTypes.bool,
  margin: PropTypes.number,
  opacity: PropTypes.number,
  padding: PropTypes.number
};
export default Box;