function _templateObject13() {
  const data = _taggedTemplateLiteral(["\n  &::-moz-placeholder { color: ", "; }\n  &::-webkit-input-placeholder { color: ", "; }\n  &:-moz-placeholder { color: ", "; }\n  &:-ms-input-placeholder { color: ", "; }\n"]);

  _templateObject13 = function () {
    return data;
  };

  return data;
}

function _templateObject12() {
  const data = _taggedTemplateLiteral(["\n  direction: rtl;\n  max-width: 100%;\n  overflow: hidden;\n  text-align: left;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"]);

  _templateObject12 = function () {
    return data;
  };

  return data;
}

function _templateObject11() {
  const data = _taggedTemplateLiteral(["\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"]);

  _templateObject11 = function () {
    return data;
  };

  return data;
}

function _templateObject10() {
  const data = _taggedTemplateLiteral(["\n  flex: 1;\n  height: 100%;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n"]);

  _templateObject10 = function () {
    return data;
  };

  return data;
}

function _templateObject9() {
  const data = _taggedTemplateLiteral(["\n  &:disabled {\n    background-color: ", ";\n    border-color: ", ";\n  }\n"]);

  _templateObject9 = function () {
    return data;
  };

  return data;
}

function _templateObject8() {
  const data = _taggedTemplateLiteral(["\n  appearance: none;\n  -webkit-appearance: none;\n  background: transparent;\n  border: 0;\n  display: inline;\n  line-height: 1.2;\n  margin: 0;\n  outline: none;\n  padding: 0;\n"]);

  _templateObject8 = function () {
    return data;
  };

  return data;
}

function _templateObject7() {
  const data = _taggedTemplateLiteral(["\n  ", "\n  cursor: pointer;\n  line-height: 0;\n  position: relative;\n  &:before {\n    content: \"\";\n    position: absolute;\n    min-width: 40px;\n    min-height: 40px;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"]);

  _templateObject7 = function () {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  ", "\n  font-size: ", "px;\n"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["box-shadow: 2px 2px 6px ", ";"]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["&:hover {\n  border: 1px solid ", ";\n}"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["&:focus {\n  outline: none;\n  border: 1px solid ", ";\n  box-shadow: 0 0 3px 2px ", ";\n}"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  border-radius: 2px;\n  border: 1px solid transparent;\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  font-size: ", "px;\n  justify-content: flex-start;\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  html, div, span, applet, object, iframe,\n  h1, h2, h3, h4, h5, h6, p, blockquote, pre,\n  a, abbr, acronym, address, big, cite, code,\n  del, dfn, em, img, ins, kbd, q, s, samp,\n  small, strike, strong, sub, sup, tt, var,\n  b, u, i, center,\n  dl, dt, dd, ol, ul, li,\n  fieldset, form, label, legend,\n  table, caption, tbody, tfoot, thead, tr, th, td,\n  article, aside, canvas, details, embed,\n  figure, figcaption, footer, header, hgroup,\n  main, menu, nav, output, ruby, section, summary,\n  time, mark, audio, video {\n    margin: 0;\n    padding: 0;\n    border: 0;\n    font-size: 100%;\n    vertical-align: baseline;\n  }\n  * { box-sizing: border-box; outline: 0; }\n  *[hidden] { display: none; }\n  ol, ul {\n    list-style: none;\n  }\n  blockquote, q {\n    quotes: none;\n  }\n  blockquote:before, blockquote:after, q:before, q:after {\n    content: '';\n    content: none;\n  }\n  table {\n    border-collapse: collapse;\n    border-spacing: 0;\n  }\n  html, #root {\n    height: 100%;\n  }\n  ", "\n  body {\n    height: 100%;\n    overflow: hidden;\n    line-height: 1;\n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto', sans-serif;\n    color: #333;\n  }\n  .login-btn {\n    width: 90%;\n    height: 40px;\n    background: #399fef;\n    color: #fff;\n    font-weight: 700;\n    border: none;\n    border-radius: 3px;\n    font-size: 1.25rem;\n  }\n  .recover-pass {\n    background: #fff;\n    border: none;\n    color: ", ";\n    cursor: pointer;\n  }\n  button {\n    cursor: pointer;\n  }\n  form {\n    width: 95%;\n  }\n\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css, createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import colors, { convertHexToRGB } from "./colors";
export const GlobalStyles = createGlobalStyle(_templateObject(), normalize, colors.blue);
export const smallFontSize = 15;
export const baseFontSize = 19;
export const mediumFontSize = 23;
export const largeFontSize = 35;
export const smallLineHeight = 1.2;
export const baseLineHeight = 1.5;
export const borderWidth = 1;
export const borderRadius = 2;
export const inputStyles = css(_templateObject2(), colors.dark, ({
  small
}) => small ? 13 : 15, () => inputFocus, () => inputHover, () => inputPlaceholder, () => inputDisabled);
export const inputFocus = css(_templateObject3(), convertHexToRGB(colors.primary, .8), convertHexToRGB(colors.secondary, .6));
export const inputHover = css(_templateObject4(), convertHexToRGB(colors.primary, .8));
export const elevation = css(_templateObject5(), convertHexToRGB(colors.black, .3));
export const inputReset = css(_templateObject6(), () => elementReset, baseFontSize);
export const buttonReset = css(_templateObject7(), () => elementReset);
export const elementReset = css(_templateObject8());
export const inputDisabled = css(_templateObject9(), colors.grayLight, colors.disabled);
export const absoluteFill = css(_templateObject10());
export const truncate = css(_templateObject11());
export const truncateReverse = css(_templateObject12());
export const inputPlaceholder = css(_templateObject13(), colors.dark, colors.dark, colors.dark, colors.dark);