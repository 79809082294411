import React from 'react';
import { Container } from "./styles";
import SmallCard from "./components/SmallCard";
import OeeCard from "./components/OeeCard";
import Oee from "./components/Oee";
import Volume from "./components/Volume";

function General() {
  return React.createElement(Container, null, React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true,
    dark: true
  }, React.createElement(OeeCard, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)), React.createElement(SmallCard, {
    showHeader: true
  }, React.createElement(Oee, null), React.createElement(Volume, null)));
}

export default General;