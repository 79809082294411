function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n    color: ", ";\n    display: ", ";\n    font-size: ", "px;\n    font-weight: ", ";\n    line-height: 1;\n    margin: 0;\n    margin-bottom: ", "px;\n    ", "\n    ", "\n  "]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled, { css } from 'styled-components';
import colors from "../../colors";
import { baseFontSize, largeFontSize, mediumFontSize, smallFontSize, truncate, truncateReverse } from "../../settings";
const MULTIPLIER = 1;
const MARGIN_BOTTOM = 6;
const Title = styled.h1.withConfig({
  displayName: "Title",
  componentId: "cizti3-0"
})(_templateObject(), ({
  color,
  hasNoMarginBottom,
  isBlock,
  isBold,
  isDanger,
  isDark,
  isDisabled,
  isLarge,
  isLight,
  isMedium,
  isPrimary,
  isSecondary,
  isSmall,
  isSuccess,
  isTruncated,
  isTruncatedReverse,
  isWarning,
  size
}) => {
  if (isSmall) size = smallFontSize * MULTIPLIER;
  if (isMedium) size = mediumFontSize * MULTIPLIER;
  if (isLarge) size = largeFontSize * MULTIPLIER;
  if (isLight) color = colors.light;
  if (isDark) color = colors.dark;
  if (isPrimary) color = colors.primary;
  if (isSecondary) color = colors.secondary;
  if (isSuccess) color = colors.success;
  if (isWarning) color = colors.warning;
  if (isDanger) color = colors.danger;
  if (isDisabled) color = colors.disabled;
  return css(_templateObject2(), color, isBlock ? 'block' : 'inline-block', size, isBold ? 'bold' : 'initial', hasNoMarginBottom ? 0 : MARGIN_BOTTOM, isTruncated && truncate, isTruncatedReverse && truncateReverse);
});
Title.defaultProps = {
  color: 'inherit',
  hasNoMarginBottom: false,
  isBlock: true,
  isBold: false,
  isDanger: false,
  isDark: false,
  isDisabled: false,
  isLarge: false,
  isLight: false,
  isMedium: false,
  isPrimary: false,
  isSecondary: false,
  isSmall: false,
  isSuccess: false,
  isTruncated: false,
  isTruncatedReverse: false,
  isWarning: false,
  size: baseFontSize * MULTIPLIER
};
export default Title;