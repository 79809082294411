import moment from 'moment';
/**
 * @function
 * @description Return capitalized string
 * @param {String} s
 * Return - String
 */

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
/**
 * @function
 * @description Return if a number is invalid
 * @param {*} number
 * Return - Boolean
 */

export function isInvalidNumber(number) {
  return isNaN(number) || !isFinite(number) || number === Infinity || number === 'Infinity' || number === -Infinity || number === '-Infinity' || number === NaN || number === 'NaN' || number === '∞' || number === '-∞' || number === undefined || number === 'undefined' || number === null || number === 'null';
}
;
export function isZeroOrInvalid(number) {
  return isNaN(number) || !isFinite(number) || number === Infinity || number === 'Infinity' || number === -Infinity || number === '-Infinity' || number === NaN || number === 'NaN' || number === '∞' || number === '-∞' || number === undefined || number === 'undefined' || number === null || number === 'null' || number === 0 || typeof number === 'string';
}
;
export const fixNumber = (number, float, locale) => {
  if (isInvalidNumber(number)) return number;

  if (float > 0) {
    return number.toLocaleString(locale, {
      maximumFractionDigits: float,
      minimumFractionDigits: 0
    });
  }
};
export function convertToDaysHoursNoSeconds(value, time) {
  //if (isInvalidNumber(value)) return '';
  let durationInSeconds = moment.duration(value, time);

  if (durationInSeconds.days() > 0) {
    return durationInSeconds.days() + 'd ' + moment.utc(durationInSeconds.valueOf()).format('HH:mm');
  }

  return moment.utc(durationInSeconds.valueOf()).format('HH:mm');
}
;
export function getTotalElapsedTime(value, time) {
  let durationInSeconds = moment.duration(value, time);
  let total = '';
  if (durationInSeconds.days() > 0) total = durationInSeconds.days() + 'd ';
  if (durationInSeconds.hours() > 0) total += durationInSeconds.hours() + 'h ';
  if (durationInSeconds.minutes() > 0) total += durationInSeconds.minutes() + 'm ';
  return total;
}
/**
 * @function
 * @description Return a percent of a determined time
 * @param {number} elapsedTime
 * @param {number} downtime
 * Return String
 */

export function getPercentHour(elapsedTime, downtime) {
  if (elapsedTime <= 60 || downtime === 0) return '0';
  let elapsedMinutes = Math.round(elapsedTime / 60);
  let percent = downtime * 100 / elapsedMinutes;
  return percent.toFixed(1);
}
;
export const prettyNumber = (number, float, locale) => {
  const thousand = 1000;
  const million = 1000000;
  const billion = 1000000000;
  const trillion = 1000000000000;
  let parsedNumber = {};
  const original = number;
  parsedNumber.original = original;

  if (number < thousand) {
    number = String(number);
    if (float) number = fixNumber(number, float, locale);
    if (number === 'NaN' || number === 'null') number = '-';
    parsedNumber.formatted = number;
    parsedNumber.value = number;
    parsedNumber.token = null;
    return parsedNumber;
  }

  if (number >= thousand && number <= 1000000) {
    number = number / thousand;
    if (float) number = fixNumber(number, float, locale);
    parsedNumber.formatted = number + 'k';
    if (number === 'NaN' || number === 'null') parsedNumber.formatted = '-';
    parsedNumber.value = number;
    parsedNumber.token = 'k';
    return parsedNumber;
  }

  if (number >= million && number <= billion) {
    number = number / million;
    if (float) number = fixNumber(number, float, locale);
    parsedNumber.formatted = number + 'MM';
    if (number === 'NaN' || number === 'null') parsedNumber.formatted = '-';
    parsedNumber.value = number;
    parsedNumber.token = 'MM';
    return parsedNumber;
  }

  if (number >= billion && number <= trillion) {
    number = number / billion;
    if (float) number = fixNumber(number, float, locale);
    parsedNumber.formatted = number + 'B';
    if (number === 'NaN' || number === 'null') parsedNumber.formatted = '-';
    parsedNumber.value = number;
    parsedNumber.token = 'B';
    return parsedNumber;
  } else {
    number = number / trillion;
    if (float) number = fixNumber(number, float, locale);

    if (number === 'NaN' || isNaN(number) || number === 'null') {
      parsedNumber.formatted = '-';
    } else {
      parsedNumber.formatted = number + 'T';
    }

    parsedNumber.value = number;
    parsedNumber.token = 'T';
    return parsedNumber;
  }
};