function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: 230px;\n  height: ", "px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n\n  p {\n    margin-right: 10px;\n    font-size: 15px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: ", "px;\n  background: #131823;\n  color: #FFF;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 15px;\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 615px;\n  height: ", "px;\n  background: #FFF;\n  box-shadow: 2px 5px 7px -2px rgba(0,0,0,0.57);\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import { Circle } from "../..";
import styled from 'styled-components';
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "mdmqcs-0"
})(_templateObject(), props => props.small ? 322 : 470);
const Header = styled.div.withConfig({
  displayName: "Header",
  componentId: "mdmqcs-1"
})(_templateObject2(), props => props.small ? 38 : 46);
const Reason = styled.div.withConfig({
  displayName: "Reason",
  componentId: "mdmqcs-2"
})(_templateObject3(), props => props.small ? 38 : 46);
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "mdmqcs-3"
})(_templateObject4());

const Card = ({
  children,
  showHeader,
  data,
  small
}) => {
  var _data$json_downtimes;

  const isStopped = data === null || data === void 0 ? void 0 : (_data$json_downtimes = data.json_downtimes) === null || _data$json_downtimes === void 0 ? void 0 : _data$json_downtimes.find(item => item.end_time === null);
  return React.createElement(Container, {
    small: small
  }, showHeader && React.createElement(Header, {
    small: small
  }, React.createElement("p", {
    style: {
      fontWeight: 'bold',
      fontSize: 26
    }
  }, data === null || data === void 0 ? void 0 : data.nm_equipment), React.createElement(Reason, {
    small: small
  }, React.createElement("div", {
    style: {
      width: '203px',
      textAlign: 'end',
      paddingRight: '7px'
    }
  }, isStopped ? React.createElement("p", null, isStopped === null || isStopped === void 0 ? void 0 : isStopped.description) : ''), React.createElement(Circle, {
    color: isStopped ? '#f00' : '#1BC100'
  }))), React.createElement(Content, null, children));
};

export default Card;