function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject8() {
  const data = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  text-transform: uppercase;\n  font-weight: bold;\n"]);

  _templateObject8 = function () {
    return data;
  };

  return data;
}

function _templateObject7() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject7 = function () {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral([""]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n    border-radius: 100%;\n    border: none;\n    outline: none;\n    padding: 0;\n    width: ", "px;\n    height: ", "px;\n    color: ", ";\n    path {\n      transition: all .15s ease-in-out;\n      fill: ", ";\n    }\n    &:hover {\n      path { fill: ", "; }\n    }\n  "]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n      ", " { color: ", "; }\n      path { fill: ", "; }\n    "]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n    ", " {\n      transition: all .15s ease-in-out;\n      color: ", ";\n    }\n    path {\n      transition: all .15s ease-in-out;\n      fill: ", ";\n    }\n  "]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  ", "\n  align-items: center;\n  background-color: ", ";\n  border-color: ", ";\n  border-radius: ", "px;\n  border-style: solid;\n  border-width: ", "px;\n  display: flex;\n  flex-direction: row;\n  height: ", "px;\n  justify-content: center;\n  margin-bottom: ", "px;\n  margin-left: ", "px;\n  margin-right: ", "px;\n  margin-top: ", "px;\n  outline: none;\n  padding-left: ", "px;\n  padding-right: ", "px;\n  transition: all .15s ease-in-out;\n  ", "\n  ", "\n  ", "\n  &:disabled { cursor: not-allowed; }\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n    ", "\n  }\n  ", "\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors, { invertColor, darken } from "../../colors";
import { baseFontSize, buttonReset, largeFontSize, mediumFontSize, smallFontSize } from "../../settings";
import * as customColors from "./customColors";
const COLOR_DEFAULT = colors.blueDark;
const BORDER_RADIUS = 10;
const LEFT_RIGHT_PADDING = 0;
const BORDER_WIDTH = 0;
const Wrapper = styled.button.withConfig({
  displayName: "Wrapper",
  componentId: "sc-1td1bz-0"
})(_templateObject(), ({
  backgroundColor,
  backgroundColorHover,
  borderColor,
  borderColorHover,
  labelColor,
  labelColorHover,
  isBare,
  isExpanded,
  isFullWidth,
  isRounded,
  offsetHL,
  offsetHR,
  offsetVB,
  offsetVT,
  size,
  theme
}) => isBare ? buttonReset : css(_templateObject2(), buttonReset, backgroundColor, borderColor, BORDER_RADIUS, BORDER_WIDTH, size, offsetVB || 0, offsetHL || 0, offsetHR || 0, offsetVT || 0, LEFT_RIGHT_PADDING || size / 2.2, LEFT_RIGHT_PADDING || size / 2.2, isExpanded && 'flex: 1;', isFullWidth && 'width: 100%;', !theme.custom && css(_templateObject3(), Label, labelColor, labelColor), backgroundColorHover, borderColorHover, !theme.custom && css(_templateObject4(), Label, labelColorHover, labelColorHover), isRounded && css(_templateObject5(), size, size, ({
  labelColor
}) => labelColor, ({
  labelColor
}) => labelColor, ({
  labelColorHover
}) => labelColorHover)));
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "sc-1td1bz-1"
})(_templateObject6());
const Label = styled.span.withConfig({
  displayName: "Label",
  componentId: "sc-1td1bz-2"
})(_templateObject7(), ({
  fontSize
}) => css(_templateObject8(), fontSize));

const Button = props => {
  const {
    children,
    onClick,
    theme
  } = props,
        rest = _objectWithoutPropertiesLoose(props, ["children", "onClick", "theme"]);

  let size = 65;
  let fontSize = mediumFontSize;
  let colorsObject = {
    backgroundColor: COLOR_DEFAULT,
    backgroundColorHover: darken(COLOR_DEFAULT, .05),
    borderColor: darken(COLOR_DEFAULT, .1),
    borderColorHover: darken(COLOR_DEFAULT, .2),
    labelColor: invertColor(COLOR_DEFAULT),
    labelColorHover: invertColor(COLOR_DEFAULT)
  };
  const {
    isDanger,
    isDisabled,
    isLarge,
    isMedium,
    isOutlined,
    isPrimary,
    isSecondary,
    isSmall,
    isSuccess,
    isTransparent,
    isWarning
  } = rest;
  if (isSmall) size = size - 4;
  if (isMedium) size = size + 4;
  if (isLarge) size = size + 8;
  if (isSmall) fontSize = smallFontSize;
  if (isMedium) fontSize = mediumFontSize;
  if (isLarge) fontSize = largeFontSize;

  const generateColors = state => {
    if (customColors[state]) return customColors[state];
    const keyColor = colors[state];
    if (!keyColor) return {};
    return {
      backgroundColor: keyColor,
      backgroundColorHover: darken(keyColor || COLOR_DEFAULT, .05),
      borderColor: darken(keyColor || COLOR_DEFAULT, .1),
      borderColorHover: darken(keyColor || COLOR_DEFAULT, .2),
      labelColor: invertColor(keyColor || COLOR_DEFAULT),
      labelColorHover: invertColor(keyColor || COLOR_DEFAULT)
    };
  };

  if (isDanger) colorsObject = generateColors('danger');
  if (isPrimary) colorsObject = generateColors('primary');
  if (isSecondary) colorsObject = generateColors('secondary');
  if (isSuccess) colorsObject = generateColors('success');
  if (isWarning) colorsObject = generateColors('warning');
  if (isDisabled || rest.disabled) colorsObject = generateColors('disabled');
  if (isOutlined) colorsObject = generateColors('outlined');
  if (isTransparent) colorsObject = generateColors('transparent');
  if (theme) colorsObject = generateColors(theme);
  return React.createElement(Wrapper, _extends({}, rest, {
    size: size,
    theme: {
      custom: theme
    }
  }, colorsObject, {
    disabled: isDisabled || rest.disabled,
    onClick: !(isDisabled || rest.disabled) ? onClick : null
  }), typeof children === 'string' ? React.createElement(Label, {
    fontSize: fontSize
  }, children) : typeof children === 'function' ? children(Object.assign({}, rest, {
    colors: colorsObject
  })) : children);
};

Button.defaultProps = {
  children: null,
  isBare: false,
  isDanger: false,
  isDisabled: false,
  isExpanded: false,
  isFullWidth: false,
  isLarge: false,
  isMedium: false,
  isOutlined: false,
  isPrimary: true,
  isSecondary: false,
  isSmall: false,
  isSuccess: false,
  isTransparent: false,
  isWarning: false,
  offsetHL: null,
  offsetHR: null,
  offsetVB: null,
  offsetVT: null,
  onClick: () => {},
  theme: null
};
Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element, PropTypes.array]),
  isBare: PropTypes.bool,
  isDanger: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isWarning: PropTypes.bool,
  offsetHL: PropTypes.number,
  offsetHR: PropTypes.number,
  offsetVB: PropTypes.number,
  offsetVT: PropTypes.number,
  onClick: PropTypes.func,
  theme: PropTypes.string
};
export default Button;