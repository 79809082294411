function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  width: 120px;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  .value {\n    font-size: 28px;\n    font-weight: bold;\n  }\n  .desc { \n    margin-top: 10px;\n    font-size: 20px;\n    color: #BFBFBF;\n  }\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 90px;\n  display: flex;\n  border-bottom: 2px solid #EAEAEE;\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 40px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  .title {\n    margin-left: 21px;\n    font-weight: bold;\n    font-size: 20;\n    color: #BFBFBF;\n  }\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 120px;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "h68be5-0"
})(_templateObject());
const Header = styled.div.withConfig({
  displayName: "Header",
  componentId: "h68be5-1"
})(_templateObject2());
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "h68be5-2"
})(_templateObject3());
const Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "h68be5-3"
})(_templateObject4());

function Oee() {
  return React.createElement(Container, null, React.createElement(Header, null, " ", React.createElement("span", {
    className: "title"
  }, "OEE"), " "), React.createElement(Content, null, React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "63.1% ", React.createElement(FaArrowUp, {
    size: 18,
    color: "#1BC100"
  })), React.createElement("p", {
    className: "desc"
  }, "Ontem")), React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "45.0% ", React.createElement(FaArrowDown, {
    size: 18,
    color: "#EA3939"
  })), React.createElement("p", {
    className: "desc"
  }, "Hoje")), React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "68.1% ", React.createElement(FaArrowUp, {
    size: 18,
    color: "#1BC100"
  })), React.createElement("p", {
    className: "desc"
  }, "Mes"))));
}

export default Oee;