function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  width: 180px;\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  .value {\n    font-size: 28px;\n    font-weight: bold;\n  }\n  .desc { \n    margin-top: 10px;\n    font-size: 20px;\n    color: #BFBFBF;\n  }\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 40px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  .title {\n    margin-left: 21px;\n    font-weight: bold;\n    font-size: 20;\n    color: #BFBFBF;\n  }\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-4h710l-0"
})(_templateObject());
const Header = styled.div.withConfig({
  displayName: "Header",
  componentId: "sc-4h710l-1"
})(_templateObject2());
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "sc-4h710l-2"
})(_templateObject3());
const Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "sc-4h710l-3"
})(_templateObject4());

function Volume() {
  return React.createElement(Container, null, React.createElement(Header, null, " ", React.createElement("span", {
    className: "title"
  }, "Volume"), " "), React.createElement(Content, null, React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "125,000"), React.createElement("p", {
    className: "desc"
  }, "Ontem")), React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "80,000"), React.createElement("p", {
    className: "desc"
  }, "Hoje")), React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "2,300,000"), React.createElement("p", {
    className: "desc"
  }, "Mes")), React.createElement(Box, null, React.createElement("p", {
    className: "value"
  }, "132,450"), React.createElement("p", {
    className: "desc"
  }, "Media"))));
}

export default Volume;