function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 25px;\n  height: 25px;\n  border-radius: 25px;\n  background: ", ";\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "z7ptza-0"
})(_templateObject(), props => props.color);

const Circle = ({
  color
}) => React.createElement(Container, {
  color: color
});

export default Circle;