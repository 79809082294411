function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject6() {
  const data = _taggedTemplateLiteral(["flex-grow: 1;"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n    align-items: ", ";\n    justify-content: ", ";\n  "]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["flex-wrap: wrap;"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n\n  width: ", ";\n  display: flex;\n  flex-direction: ", ";\n\n  ", "\n\n  align-items: ", ";\n  justify-content: ", ";\n  text-align: ", ";\n\n  ", ";\n\n  ", "\n\n  padding: ", ";\n\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  flex-grow: 1;\n  flex-shrink: 0;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React, { Fragment, Children } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Spacer from "../Spacer";
export const Fill = styled.div.withConfig({
  displayName: "Fill",
  componentId: "sc-1pkc7ei-0"
})(_templateObject());
const Wrapper = styled.div.withConfig({
  displayName: "Wrapper",
  componentId: "sc-1pkc7ei-1"
})(_templateObject2(), ({
  isVertical,
  isFullWidth,
  padding,
  isVerticalCentered,
  isCentered,
  isGrown,
  isWrapped
}) => css(_templateObject3(), isFullWidth ? '100%' : 'auto', isVertical ? 'column' : 'row', isWrapped && css(_templateObject4()), isVerticalCentered ? 'center' : 'flex-start', isCentered ? 'center' : 'flex-start', isCentered ? 'center' : 'left', isVertical && isCentered && css(_templateObject5(), isCentered ? 'center' : 'flex-start', isVerticalCentered ? 'center' : 'flex-start'), isGrown && css(_templateObject6()), (() => {
  let paddingToUse = padding;
  if (typeof padding === 'number') paddingToUse = padding + 'px';
  return paddingToUse;
})()));

const Group = (_ref) => {
  let {
    children,
    isVertical,
    isFullWidth,
    spaceSize,
    padding,
    isVerticalCentered,
    isCentered,
    isGrown,
    isWrapped
  } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, ["children", "isVertical", "isFullWidth", "spaceSize", "padding", "isVerticalCentered", "isCentered", "isGrown", "isWrapped"]);

  return React.createElement(Wrapper, _extends({
    isVertical: isVertical,
    isFullWidth: isFullWidth,
    spaceSize: spaceSize,
    padding: padding,
    isVerticalCentered: isVerticalCentered,
    isCentered: isCentered,
    isGrown: isGrown,
    isWrapped: isWrapped
  }, rest), Children.toArray(children).map((element, index) => React.createElement(Fragment, {
    key: index
  }, index !== 0 && React.createElement(Spacer, {
    isVertical: isVertical,
    size: spaceSize
  }), element)));
};

Group.defaultProps = {
  isCentered: null,
  isFullWidth: false,
  isGrid: false,
  isGrown: false,
  isVertical: false,
  isVerticalCentered: false,
  isWrapped: false,
  padding: 0,
  spaceSize: 5
};
Group.propTypes = {
  children: PropTypes.node.isRequired,
  isCentered: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isGrid: PropTypes.bool,
  isGrown: PropTypes.bool,
  isVertical: PropTypes.bool,
  isVerticalCentered: PropTypes.bool,
  isWrapped: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spaceSize: PropTypes.number
};
export default Group;