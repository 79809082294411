const prefix = '@overview:';
const SITE_INFO = "".concat(prefix, "site-info");
const LANGUAGE = "".concat(prefix, "language");
const USER_ROLES = "".concat(prefix, "roles");
const FIREBASE_TOKEN = "".concat(prefix, "token");
const ENTERPRISE = "".concat(prefix, "id-enterprise");
export const getSiteInfo = () => localStorage.getItem(SITE_INFO);
export const getLanguage = () => localStorage.getItem(LANGUAGE);
export const getRoles = () => localStorage.getItem(USER_ROLES);
export const getToken = () => localStorage.getItem(FIREBASE_TOKEN);
export const getEnterprise = () => localStorage.getItem(ENTERPRISE);
export const setInitialInfo = (roles, id) => {
  roles = JSON.stringify(roles);
  localStorage.setItem(USER_ROLES, roles);
  localStorage.setItem(ENTERPRISE, id);
};
export const setActiveSite = site => {
  localStorage.setItem(SITE_INFO, site);
};
export const setLanguage = lang => {
  let language = JSON.stringify(lang);
  localStorage.setItem(LANGUAGE, language);
};
export const setToken = token => {
  localStorage.setItem(FIREBASE_TOKEN, token);
};
export const removeInfo = () => {
  localStorage.clear();
};