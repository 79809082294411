function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  flex-grow: 1;\n  flex-shrink: 0;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
const Fill = styled.div.withConfig({
  displayName: "Fill",
  componentId: "it8o24-0"
})(_templateObject());
export default Fill;