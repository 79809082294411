/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Card } from 'components';
import PoInfo from 'compositions/PoInfo';
import Downtime from 'compositions/Downtime';
import { Loading } from 'components';
import { Container } from './styles';
import { GetProductionInfo, GetShift } from '../../graphql/queries.graphql';
import { SubProductionInfo, SubShift } from '../../graphql/subscription.graphql';
import { useSubscription, useLazyQuery } from '@apollo/react-hooks';
import { MainContext } from '../../contexts/MainContext';

function SecondLayout() {
  const { dispatch, state } = useContext(MainContext);
  const [getOverviewData, { loading }] = useLazyQuery(GetProductionInfo, {
    onCompleted: (data) => {
      dispatch({ type: 'OVERVIEW_DATA', payload: data?.v_c1_equipments_overview });
    } 
  });

  const [getShift] = useLazyQuery(GetShift, {
    onCompleted: (data) => {
      dispatch({ type: 'SHIFT', payload: data?.agg_eqvalues_shift });
    } 
  });
  
  useSubscription(SubProductionInfo, {
    variables: { id: [413, 416, 417, 414, 410, 411, 412, 415 ] },
    onSubscriptionData: (data) => {
      dispatch({ type: 'OVERVIEW_DATA', payload: data?.subscriptionData?.data?.v_c1_equipments_overview });
    } 
  });

  useSubscription(SubShift, {
    variables: { id: [413, 416, 417, 414, 410, 411, 412, 415 ] },
    onSubscriptionData: (data) => {
      dispatch({ type: 'SHIFT', payload: data?.subscriptionData?.data?.agg_eqvalues_shift });
    } 
  });

  useEffect(() => {
    getOverviewData({ variables: { id: [413, 416, 417, 414, 410, 411, 412, 415 ] } });
    getShift({ variables: { id: [413, 416, 417, 414, 410, 411, 412, 415 ] } });
  }, []);

  if (loading) return <Loading />;
  
  return (
    <Container>
    
      {
        state?.overviewData?.map((data) => (
          <Card key={data?.nm_equipment} data={data} small showHeader>
            <PoInfo data={data} shift={state?.shift} small />
            <Downtime small data={data} />
          </Card>
        ))
      }

      <div style={{ width: 615 }} />
    </Container>
  );

};

export default SecondLayout;
