function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: 200px;\n  height:  50px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n\n  p {\n    margin-right: 10px;\n    font-size: 18px\n  }\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 55px;\n  background: #131823;\n  color: #FFF;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 15px;\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 360px;\n  height: 420px;\n  background: ", ";\n  box-shadow: 2px 5px 7px -2px rgba(0,0,0,0.57);\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
import { Circle } from "../../../components";
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1jbxx11-0"
})(_templateObject(), props => props.isDark ? '#131823' : '#FFF');
const Header = styled.div.withConfig({
  displayName: "Header",
  componentId: "sc-1jbxx11-1"
})(_templateObject2());
const Reason = styled.div.withConfig({
  displayName: "Reason",
  componentId: "sc-1jbxx11-2"
})(_templateObject3());
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "sc-1jbxx11-3"
})(_templateObject4());

function SmallCard({
  children,
  showHeader,
  dark
}) {
  let bool = Math.floor(Math.random() * 2);
  return React.createElement(Container, {
    isDark: dark
  }, showHeader && React.createElement(Header, null, React.createElement("p", {
    style: {
      fontWeight: 'bold',
      fontSize: 26
    }
  }, "Dubuit 1"), !dark && React.createElement(Reason, null, bool === 1 ? React.createElement("p", null, "Motivo da parada") : '', React.createElement(Circle, {
    color: bool === 1 ? '#f00' : '#1BC100'
  }))), React.createElement(Content, null, children));
}

export default SmallCard;