import moment from 'moment';
const INITIAL_STATE = {
  update: '',
  overviewData: [],
  oeeData: [],
  shift: []
};

export const mainReducer = (state = INITIAL_STATE, action = {}) => {

  switch (action.type) {

  case 'OVERVIEW_DATA':
    state = {
      ...state,
      ...{ overviewData: action.payload, update: moment().format('HH:mm:ss') }
    };
    break;

  case 'OEE_DATA':
    state = {
      ...state,
      ...{ oeeData: action.payload, update: moment().format('HH:mm:ss') }
    };
    break;

  case 'SHIFT':
    state = {
      ...state,
      ...{ shift: action.payload }
    };
    break;


  default:
    break;
  }

  return state;
};
