/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { navigate } from '@reach/router';
import PackiotImage from "../../../../public/media/packiot-dark.jpg";
import { auth } from "../../../services/firebase";
import { FormContainer } from "../../../components";
import { Container } from "../styles";
import { setToken } from "../../../utils/storage";
import { client } from "../../../services/graphqlConnection";

const SignIn = () => {
  const [feedback, setFeedback] = useState('');
  client.resetStore();
  return React.createElement(Container, null, React.createElement("div", {
    className: "form-container"
  }, React.createElement("img", {
    src: PackiotImage,
    alt: "logo",
    width: "250",
    height: "60"
  }), React.createElement(FormContainer, {
    onSubmit: ({
      email,
      password
    }) => {
      auth().signInWithEmailAndPassword(email, password).then(response => {
        setToken(response.user.uid);
        navigate("".concat(process.env.baseURL, "celula/1"));
      }).catch(({
        message
      }) => {
        setFeedback(message);
      });
    },
    render: ({
      values,
      handleChange,
      handleSubmit,
      isSubmitting
    }) => React.createElement(React.Fragment, null, feedback, React.createElement("br", null), React.createElement("hr", null), React.createElement("div", {
      className: "input-login"
    }, React.createElement("label", {
      className: "label"
    }, "EMAIL"), React.createElement("span", {
      className: "icon-form"
    }, " ", React.createElement(FaEnvelope, {
      size: 14,
      color: "#dbdbdb"
    }), " "), React.createElement("input", {
      name: "email",
      disabled: isSubmitting,
      value: values.name,
      onChange: handleChange,
      style: {
        border: '1px solid'
      }
    })), React.createElement("div", {
      className: "input-login"
    }, React.createElement("label", {
      className: "label"
    }, "PASSWORD"), React.createElement("span", {
      className: "icon-form"
    }, " ", React.createElement(FaLock, {
      size: 14,
      color: "#dbdbdb"
    }), " "), React.createElement("input", {
      name: "password",
      type: "password",
      disabled: isSubmitting,
      value: values.name,
      onChange: handleChange,
      style: {
        border: '1px solid'
      }
    })), React.createElement("hr", null), React.createElement("button", {
      className: "login-btn",
      onClick: handleSubmit
    }, "SIGN IN"))
  }), React.createElement("br", null), React.createElement("button", {
    className: "recover-pass",
    onClick: () => navigate("".concat(process.env.baseURL, "auth/recovery"))
  }, "Forgot password")));
};

SignIn.propTypes = {};
export default SignIn;