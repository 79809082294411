/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import Routes from "./Routes";
import colors from "../../components/colors";
import AppHeader from "../../components/compositions/AppHeader";
import { SessionContext } from "../SessionManager"; //import { MainContext } from '../../contexts/MainContext';

export const MainContainer = () => {
  const {
    isReady
  } = useContext(SessionContext);
  if (!isReady) return null;
  return React.createElement("div", {
    style: {
      backgroundColor: colors.light,
      display: 'flex',
      height: '100vh',
      flexDirection: 'column'
    }
  }, React.createElement(AppHeader, null), React.createElement(Routes, null));
};
export default MainContainer;