function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n    color: ", ";\n    display: ", ";\n    font-size: ", "px;\n    font-weight: ", ";\n    line-height: 1.2;\n    white-space: ", ";\n    ", "\n    ", "\n    + ", " {\n      margin-top: 4px;\n    }\n    > ", " {\n      display: inline-block;\n    }\n    @media ", " {\n      font-size: ", "px;\n    }\n  "]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE } from "../../../utils/deviceSize";
import colors from "../../colors";
import { baseFontSize, largeFontSize, mediumFontSize, smallFontSize, truncate, truncateReverse } from "../../settings";
const Text = styled.span.withConfig({
  displayName: "Text",
  componentId: "sc-1d1rltl-0"
})(_templateObject(), ({
  color,
  isBold,
  isDanger,
  isDisabled,
  isInline,
  isLarge,
  isLight,
  isMedium,
  isPrimary,
  isSecondary,
  isSmall,
  isSuccess,
  isTruncated,
  isTruncatedReverse,
  isWarning,
  noWrap,
  size
}) => {
  if (isSmall) size = smallFontSize;
  if (isMedium) size = mediumFontSize;
  if (isLarge) size = largeFontSize;
  if (isLight) color = colors.lightGray1;
  if (isPrimary) color = colors.primary;
  if (isSecondary) color = colors.secondary;
  if (isSuccess) color = colors.success;
  if (isWarning) color = colors.warning;
  if (isDanger) color = colors.danger;
  if (isDisabled) color = colors.disabled;

  const responsiveSize = size => {
    let newSize = 19;

    switch (size) {
      case 15:
        newSize = 15;
        break;

      case 19:
        newSize = 16;
        break;

      case 23:
        newSize = 18;
        break;

      case 35:
        newSize = 28;
        break;
    }

    return newSize;
  };

  return css(_templateObject2(), color, isInline ? 'inline-block' : 'block', size, isBold ? 'bold' : 'initial', noWrap ? 'nowrap' : 'initial', isTruncated && truncate, isTruncatedReverse && truncateReverse, Text, Text, DEVICE.mobileL, responsiveSize(size));
});
Text.defaultProps = {
  color: colors.darkGray1,
  isBold: false,
  isDanger: false,
  isDisabled: false,
  isInline: false,
  isLarge: false,
  isLight: false,
  isMedium: false,
  isPrimary: false,
  isSecondary: false,
  isSmall: false,
  isSuccess: false,
  isTruncated: false,
  isTruncatedReverse: false,
  isWarning: false,
  noWrap: false,
  size: baseFontSize
};
export default Text;